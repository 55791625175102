import React, { ComponentType } from 'react';
import { ApolloProvider } from '@apollo/react-hooks';
import ApolloClient from 'apollo-boost';
import { Router, RouteComponentProps } from '@reach/router'

import { CredentialsTypeSelection } from 'components/participant/participant-credential-selection'
import { ParticipantHome } from 'components/participant/participant-home'
import { ParticipantFlow } from 'components/participant/participant-flow'
import { PerformanceProfile } from 'components/participant/performance-profile'
import { PerformerEdit } from 'components/participant/performer-edit'
import { PrivateParticipantRoute } from 'components/private-route'
import CLIENT_ROUTES from 'constants/endpoints'
import { getUserToken } from 'services/auth/auth';

type Route = (props: RouteComponentProps<any>) => ComponentType<any>

const ParticipantLoginRoute: Route = () => (<CredentialsTypeSelection />)

const ParticipantPage = () => {
  return (
    <ApolloProvider client={new ApolloClient({
      uri: `${process.env.GATSBY_API_URL}/graphql`,
      headers: {
        Authorization: `Bearer ${getUserToken()}`,
      },
    })}>
      <Router>
        <ParticipantLoginRoute path={CLIENT_ROUTES.PARTICIPATE.LOGIN} />
        <PrivateParticipantRoute
          path={CLIENT_ROUTES.PARTICIPATE.PROFILE}
          component={PerformanceProfile}
        />
        <PrivateParticipantRoute
          path={CLIENT_ROUTES.PARTICIPATE.EDIT_PERFORMER}
          component={PerformerEdit}
        />
        <PrivateParticipantRoute
          path={CLIENT_ROUTES.PARTICIPATE.EDIT_PERFORMANCE}
          component={ParticipantFlow}
        />
        <PrivateParticipantRoute
          path={CLIENT_ROUTES.PARTICIPATE.ADD_PERFORMANCE}
          component={ParticipantFlow}
        />
        <PrivateParticipantRoute
          path={CLIENT_ROUTES.PARTICIPATE.CREATE}
          component={ParticipantFlow}
        />
        <PrivateParticipantRoute
          path={CLIENT_ROUTES.PARTICIPATE.HOME}
          component={ParticipantHome}
        />
      </Router>
    </ApolloProvider>
  )
}

export default ParticipantPage;
