import React from 'react'
import { gql } from 'apollo-boost'
import { useMutation } from '@apollo/react-hooks'
import { navigate } from 'gatsby'
import { Box } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { path } from 'ramda'

import { Layout } from 'components/layouts/main-layout'
import CLIENT_ROUTES from 'constants/endpoints'
import ERRORS from 'constants/errors'
import { PerformerRegistration, FormValues } from './components/performer-registration'
import { uploadFile } from 'services/upload'
import { createURL } from 'utilities'
import { isValue } from 'utilities/helpers'

interface Props {
  performerId: string;
}

export const PerformerEdit = ({ performerId }: Props) => {
  const [updatePerformer] = useMutation(UPDATE_PERFORMER)
  const { t } = useTranslation()

  const handleSubmit = async ({ name, genre, membersCount, website, about, image }: FormValues) => {
    let imageId
    if (isValue(image) && typeof image !== 'string') {
      const response = await uploadFile((image as File))
      const errorId = path(
        ['response', 'data', 'message', 0, 'messages', 0, 'id'],
        response,
      )
      if (errorId && errorId === ERRORS.ULOAD_SIZE_LIMIT){
        return { image: t('validation.upload-size')}
      } else {
        imageId = response
      }
    }
    const variables: any = {
      variables: {
        id: performerId,
        name,
        genre,
        membersCount: parseInt((membersCount as string), 10),
        website,
        about,
      }
    }

    if (imageId) {
      variables.variables.imageId = imageId
    } else if (!image){
      variables.variables.imageId = null;
    }

    return updatePerformer(variables).then(() => {
      navigate(
        createURL(CLIENT_ROUTES.PARTICIPATE.PROFILE, { performerId })
      )
      return undefined
    }).catch((response) => {
      const regex = RegExp('SQLITE_CONSTRAINT: UNIQUE');
      const parsedResponse  = {...response};
      if (parsedResponse.message && regex.test(parsedResponse.message)){
        return {  name: t('validation.performer-unique')}
      }
      return {}
    })
  }

  const skipSubmit = () => {
    navigate(
      createURL(CLIENT_ROUTES.PARTICIPATE.PROFILE, { performerId })
    )
  }

  return (
    <Layout
      white
      pageTitle={t('participate.edit-performer')}
      pageName={t('participate.edit-performer')}
    >
      <Box p={4}>
        <PerformerRegistration
          onSubmit={handleSubmit}
          buttonTitle={t('button.save')}
          nextStep={skipSubmit}
          data={{
            performerId,
          }}
        />
      </Box>
    </Layout>
  )
}

const UPDATE_PERFORMER = gql`
  mutation UpdatePerformer ($id: ID!, $name: String!, $genre: ID!, $membersCount: Int!, $website: String, $about: String, $imageId: ID) {
    updatePerformer(input: {
      where: { id: $id }
      data: { name: $name, genre: $genre, membersCount: $membersCount, website: $website, about: $about, image: $imageId }
    }) {
      performer { id }
    }
  }
`;
