import React from 'react'
import { navigate } from 'gatsby'
import { useQuery as useQueryRest } from 'react-query'
import {
  makeStyles,
  Grid,
  Theme,
  InputAdornment,
  MenuItem,
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { object, string, number } from 'yup'
import { FormRenderProps } from 'react-final-form'
import { FormApi } from 'final-form'
import axios from 'axios'
import CLIENT_ROUTES from 'constants/endpoints'

import { TextField } from 'components/text-field'
import { Form } from 'components/final-form/form'
import { Field } from 'components/final-form/field'
import { ProgressButton } from 'components/progress-button'
import { LogoSelection } from 'components/logo-selection'
import { Performer } from 'types'
import { StepProps } from './wizard'
import { getUserToken } from 'services/auth/auth';
import { isValue, fixUrlProtocol, getPlainUrl } from 'utilities'
import { SelectField } from 'components/select-field'
import gql from 'graphql-tag'
import { useQuery } from '@apollo/react-hooks'

const useFormStyles = makeStyles((theme: Theme) => ({
  credentialsContainer: {
    '&>*:not(:last-child)': { // One could use spacing prop on the Grid component, but it adds padding around the item and breaks the layout
      marginBottom: theme.spacing(2)
    }
  }
}));

const getOwnPerformers = () => {
  return axios.get(`${process.env.GATSBY_API_URL}/performers/own`, {
    headers: {
      Authorization: `Bearer ${getUserToken()}`,
    }});
}

const GENRES = gql`
{
  genres {id, name, name_en, name_ua }
}`;


interface OwnProps {
  buttonTitle?: string;
}

type Props = StepProps & OwnProps;

export interface FormValues {
  name: string;
  genre: string;
  membersCount: string | number;
  website?: string | null;
  about?: string | null;
  image?: string | File | { url: string } | null;
}

const getForm = (buttonTitle?: string) => ({
  handleSubmit,
  submitting,
  hasValidationErrors,
}: FormRenderProps<FormValues>) => {
  const { t, i18n } = useTranslation();
  const classes = useFormStyles();
  const { data: genreData } = useQuery(GENRES);
  return (
    <form noValidate onSubmit={handleSubmit}>
      <Grid container direction='column' spacing={2} alignItems='center'>
        <Grid item xs={12} container spacing={2}>
          <Grid item container xs={12} sm={7} className={classes.credentialsContainer} direction='column' >
            <Grid item>
              <Field
                name='name'
                label={t('participate.name')}
                component={TextField}
                disabled={submitting}
                fullWidth autoFocus required
              />
            </Grid>
            <Grid item>
              <Field
                name='genre'
                label={t('participate.genre')}
                component={SelectField}
                disabled={submitting}
                fullWidth autoFocus required
              >
                {
                  genreData && genreData.genres.map(({ id, name, name_en, name_ua }: Genre) =>
                    <MenuItem key={id} value={id}>{
                      i18n.language === 'lt-LT'
                        ? name
                        : i18n.language === 'en-US'
                          ? name_en : name_ua
                    }</MenuItem>)
                }
              </Field>
            </Grid>
            <Grid item>
              <Field
                name='membersCount'
                label={t('participate.members-count')}
                type='number'
                inputProps={{
                  min: 1,
                  max: 99,
                }}
                component={TextField}
                disabled={submitting}
                fullWidth required
              />
            </Grid>
            <Grid item>
              <Field
                name='website'
                label={t('participate.website')}
                component={TextField}
                disabled={submitting}
                startAdornment={
                  <InputAdornment position='start'>{'http://'}</InputAdornment>
                }
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid item container justify='center' xs={12} sm={5}>
            <Field
              name='image'
              component={LogoSelection}
            />
          </Grid>
          <Grid item xs={12}>
            <Field
              name='about'
              label={t('participate.about')}
              component={TextField}
              disabled={submitting}
              fullWidth
              multiline
              InputProps={{
                rows: 4,
              }}
            />
          </Grid>
        </Grid>
        <Grid item container justify='flex-end'>
          <ProgressButton
            variant='contained'
            color='primary'
            size='large'
            type='submit'
            disabled={submitting || hasValidationErrors}
            progress={submitting}
          >
            {buttonTitle || t('button.next')}
          </ProgressButton>
        </Grid>
      </Grid>
    </form>
  )
}

const mapPerformer = (performer: FormValues): FormValues => ({
  ...performer,
  website: performer.website ? getPlainUrl(performer.website) : undefined,
  image: isValue(performer.image)
    ? `${process.env.GATSBY_API_URL}${performer.image.url}`
    : undefined,
})

export const PerformerRegistration = ({
  onSubmit,
  nextStep,
  buttonTitle,
  data: { performerId }
}: Props) => {
  const registrationSchema = object().shape({
    name: string().required(),
    genre: string().required().nullable(),
    website: string().nullable(),
    membersCount: number().min(1).max(99).required(),
    about: string().nullable(),
    image: string().nullable(),
  })
  const handleSubmit = async (data: FormValues, { getState }: FormApi<FormValues>) => {
    if (getState().dirty) {
      if (data.website) {
        data.website = fixUrlProtocol(data.website as string)
      }
      return onSubmit(data);
    } else {
      nextStep && nextStep()
      return undefined
    }
  }

  let initial: FormValues = { name: '', membersCount: 1 }

  if (performerId) {
    const { data: performerResponse } = useQueryRest('performers', getOwnPerformers, {
      cacheTime: 0
    });
    const performer: Performer[] = performerResponse?.data.filter(
      ({ id }: Performer) => id.toString() === performerId
    )
    if (performer && performer.length) {
      initial = {
        ...performer[0],
        genre: performer[0].genre && performer[0].genre.id
      }
    } else if (performer) {
      navigate(CLIENT_ROUTES.PARTICIPATE.HOME)
    }
  }

  return (
    <Form
      onSubmit={handleSubmit}
      validationSchema={registrationSchema}
      initialValues={mapPerformer(initial)}
      render={getForm(buttonTitle)}
    />
  )
}

