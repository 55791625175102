import React, { useState, useRef } from 'react'
import { gql } from 'apollo-boost'
import { useMutation, useQuery } from '@apollo/react-hooks'
import { navigate } from 'gatsby'
import { useTranslation } from 'react-i18next'
import {
  Box,
  Paper,
  Grid,
  List,
  ListItem,
  Typography,
  Button,
  makeStyles,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  Theme,
  Tooltip,
  IconButton,
  Chip
} from '@material-ui/core'
import { Add, BlockOutlined, DeleteOutline as Delete, Edit, Power, PowerOff } from '@material-ui/icons'
import { pathOr, path } from 'ramda'

import ENDPOINTS from 'constants/endpoints'
import { decimalHourToSexagesimal } from 'utilities/decimalHourToSexagesimalHour'
import { createURL } from 'utilities/url'
import { FindPerformancesResponse, Location, Performance } from 'types'
import { red } from '@material-ui/core/colors'

const useStyles = makeStyles((theme: Theme) => ({
  emptyText: {
    textAlign: 'center',
  },
  bold: {
    fontWeight: 'bold',
  },
  dialog: {
    minWidth: 600,
  },
  menu: {
    '& > li:not(:last-child)': {
      borderBottom: `1px solid ${theme.palette.grey[300]}`,
    }
  },
}))

interface Props {
  performerId: string;
}

export const PerformancesList = ({ performerId }: Props) => {
  const rootRef = useRef<HTMLDivElement>(null);
  const [isModalOpen, setModalOpen] = useState(false)
  const [performanceToDelete, setPerformanceToDelete] = useState()
  const [deletePerformance] = useMutation(DELETE_PERFORMANCE)
  const { t } = useTranslation()
  const classes = useStyles()

  const { data: performanceData, refetch } = useQuery<FindPerformancesResponse>(FIND_PERFORMANCES, {
    fetchPolicy: 'network-only',
    variables: {
      id: performerId
    }
  })

  const handleEdit = (performanceId: number) => () => {
    navigate(
      createURL(
        ENDPOINTS.PARTICIPATE.EDIT_PERFORMANCE,
        { performerId, performanceId }
      )
    )
  }

  const handleAdd = () => {
    navigate(
      createURL(
        ENDPOINTS.PARTICIPATE.ADD_PERFORMANCE,
        { performerId }
      )
    )
  }

  const handleConfirm = () => {
    deletePerformance(
      { variables: { id: performanceToDelete} }
    ).then(() => { refetch() })

    toggleForm()
    setPerformanceToDelete(() => undefined)
  }

  const toggleForm = () => {
    setModalOpen((current) => !current)
  }

  const handleDelete = (performanceId: number) => () => {
    setPerformanceToDelete(performanceId)
    toggleForm()
  }

  const getTimePeriod = (hourFrom: number, hourTo: number) =>
    `${decimalHourToSexagesimal(hourFrom)} - ${decimalHourToSexagesimal(hourTo)}`

  const getLocationName = (location: Location) =>
    `${pathOr('-', ['name'], location)} (${pathOr('-', ['city', 'name'], location)})`

  const isAcoustic = (location: Location) => path(['zone', 'is_acoustic'], location)

  const renderList = () => performanceData && performanceData.performances.map(
    ({ id, location, hourFrom, hourTo, cancelled, cancelReason }: Performance) => (
      <ListItem key={id}>
        <Grid container alignItems='center' justify='space-between'>
          <Grid item xs={4}>
            <Typography>
              {getLocationName(location as Location)}
            </Typography>
          </Grid>
          <Grid item xs>
            <Typography className={classes.bold}>
              {getTimePeriod(hourFrom as number, hourTo as number)}
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <Tooltip title={isAcoustic(location as Location) ? t('participate.acoustic') : t('participate.non-acoustic')}>
              <IconButton disableRipple aria-label={t('participate.electricity')}>
                {isAcoustic(location as Location) ? <PowerOff/> : <Power/>}
              </IconButton>
            </Tooltip>
          </Grid>
          {!cancelled
            ? (
              <Grid item xs={4} container spacing={1} justify='flex-end'>
                <Grid item>
                  <Button size='small' endIcon={<Edit />} onClick={handleEdit(id as number)}>
                    {t('button.edit')}
                  </Button>
                </Grid>
                <Grid item>
                  <Button size='small' endIcon={<Delete />} onClick={handleDelete(id as number)}>
                    {t('button.delete')}
                  </Button>
                </Grid>
              </Grid>
            )
            :  (
              <Grid item xs={4} container spacing={1} justify='flex-end'>
                <Tooltip title={cancelReason} enterTouchDelay={100}>
                  <Chip
                    label={t('participate.performance-cancelled')}
                    icon={<BlockOutlined style={{ color: red[500] }}/>}
                    color='default'
                  />
                </Tooltip>
              </Grid>
            )
          }
        </Grid>
      </ListItem>
    ))

  return (
    <div ref={rootRef}>
      <Paper elevation={2}>
        <List className={classes.menu}>
          {
          performanceData?.performances.length === 0 &&
          <Typography className={classes.emptyText}>
            {t('participate.no-performance')}
          </Typography>
          }
          {renderList()}
        </List>
      </Paper>
      <Box pt={4} display='flex' justifyContent='center'>
        <Button endIcon={<Add />} onClick={handleAdd} variant={'outlined'}>
          {t('button.add-performance')}
        </Button>
      </Box>
      <Dialog
        open={isModalOpen}
        onClose={toggleForm}
        disablePortal
        container={() => rootRef.current}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogContent className={classes.dialog}>
          <DialogContentText id="alert-dialog-description">
            {t('participate.delete-confirmation')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={toggleForm}>
            {t('button.no')}
          </Button>
          <Button onClick={handleConfirm} color='primary' variant='contained' autoFocus>
            {t('button.yes')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

const FIND_PERFORMANCES = gql`
  query performances($id: ID ) {
    performances (where: {
      performer: {
        id: $id
    }}) { id, hourFrom, hourTo, cancelled, cancelReason, location { name city { name } zone { is_acoustic } } }
  }
`

const DELETE_PERFORMANCE = gql`
  mutation DeletePerformance ($id: ID!) {
    deletePerformance(input: { where: { id: $id } }) {
      performance { id }
    }
  }
`;
