import React from 'react'
import { gql } from 'apollo-boost'
import { useQuery } from '@apollo/react-hooks'
import { navigate } from 'gatsby'
import { Grid, Box, Button, Typography, makeStyles } from '@material-ui/core'
import { Edit } from '@material-ui/icons'
import { useTranslation } from 'react-i18next'

import { Layout } from 'components/layouts/main-layout'
import { GMDYellow } from 'components/logos/gmd-main-yellow'
import CLIENT_ROUTES from 'constants/endpoints'
import { createURL} from 'utilities/url'
import { PerformancesList } from 'components/performances-table'
import { FindPerformerResponse } from 'types'
import { Description } from 'components/description'

interface Props {
  performerId: string;
}

const useStyles = makeStyles({
  title: {
    fontWeight: 'bold',
  },
})

export const PerformanceProfile = ({ performerId }: Props) => {
  const { t, i18n } = useTranslation()
  const classes = useStyles()

  const { data } = useQuery<FindPerformerResponse>(FIND_PERFORMER, {
    fetchPolicy: 'network-only',
    variables: {
      id: performerId
    }
  })

  const handleEdit = () => {
    navigate(createURL(CLIENT_ROUTES.PARTICIPATE.EDIT_PERFORMER, { performerId }))
  }

  return (
    <Layout pageName={t('participate.profile')}>
      <Grid container direction='row' spacing={2}>
        <Grid item xs={12} lg={6}>
          <Grid container spacing={1} justify='center' alignItems='center' direction='column'>
            {
              data && data.performer.image &&
              <Grid item xs={12}>
                <img style={{ maxWidth: 300 }} src={`${process.env.GATSBY_API_URL}/${data.performer.image.url}`} />
              </Grid>
            }
            {
              data && !data.performer.image &&
              <GMDYellow width={200} height={240} />
            }
            <Grid item xs={12}>
              <Typography className={classes.title} align='center' variant='h5'>
                {data?.performer.name}
              </Typography>
            </Grid>
            <Grid item>
              <Button endIcon={<Edit />} onClick={handleEdit} variant={'outlined'}>
                {t('button.edit')}
              </Button>
            </Grid>
            {
             data?.performer.genre &&
             <Grid item xs={12}>
               <Typography align='center'>
                 {
                   i18n.language === 'lt-LT'
                     ? data?.performer.genre?.name
                     : i18n.language === 'en-US'
                       ? data?.performer.genre?.name_en
                       : data?.performer.genre?.name_ua
                 }
               </Typography>
             </Grid>
            }
            {
              data && data?.performer.membersCount > 1 &&
              <Grid item xs={12}>
                <Typography>
                  {`${data?.performer.membersCount} ${t('participate.members', { postProcess: 'interval', count: data?.performer.membersCount })}`}
                </Typography>
              </Grid>
            }
            <Grid item xs={12}>
              <Box p={1}>
                <Description align='left'>
                  {data?.performer.about}
                </Description>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Typography
                align='center'
                component='a'
                target='_blank'
                href={data?.performer.website}
              >
                {data?.performer.website}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} lg={6} container direction='column' justify='center'>
          <PerformancesList performerId={performerId} />
        </Grid>
      </Grid>

    </Layout>
  )
}

const FIND_PERFORMER = gql`
  query performer($id: ID!) {
    performer (id: $id) { id, name, genre { id, name, name_en, name_ua}, membersCount, website, about, image { url } }
  }
`;
