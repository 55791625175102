import React, { ReactNode } from 'react';
import {
  Button,
  Paper,
  MenuItem,
  MenuList,
  Typography,
  Grid,
  makeStyles,
  withStyles,
  Theme,
  fade,
  FormHelperText,
} from '@material-ui/core'
import { DeleteOutlined } from '@material-ui/icons'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'

import { decimalHourToSexagesimal } from '../utilities'

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    width: '100%',
    maxHeight: 400,
    overflowX: 'auto',
  },
  exception: {
    backgroundColor: theme.palette.action.disabled,
  },
  selected: {
    backgroundColor: `${fade(theme.palette.primary.main, .4)} !important`,
  },
  fullWidth: {
    width: '100%',
  }
}));

const StyledMenuItem = withStyles((theme: Theme) => ({
  root: {
    justifyContent: 'center',
    borderRadius: 2,
    margin: theme.spacing(0, 1),
  }
}))(MenuItem);

export interface TimeInterval {
  hourFrom?: number | null;
  hourTo?: number | null;
}

export interface Props {
  value: TimeInterval;
  onChange: (interval: TimeInterval) => void;
  startHour?: number;
  endHour?: number;
  slotsPerHour?: number;
  takenTimeSlots?: Array<number>; // Each number indicates a slot of 30min duration
  error?: boolean;
  helperText?: ReactNode;
}

export const TimeRangePicker = ({
  value,
  onChange,
  startHour = 8,
  endHour = 20,
  slotsPerHour = 2,
  takenTimeSlots = [],
  error,
  helperText,
}: Props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { hourFrom, hourTo } = value
  const SLOT_STEP = 1 / slotsPerHour
  const SLOT_COUNT = (endHour - startHour) * slotsPerHour
  const TIME_TABLE = Array
    .from({ length: SLOT_COUNT })
    .map((_, slotDuration: number) => startHour + (slotDuration  / slotsPerHour))

  const isRange = !!hourFrom && !!hourTo

  const resetTime = () => {
    onChange({
      hourFrom: null,
      hourTo: null,
    })
  }

  const getSlotDuration = () => {
    const delta = (hourTo as number) - (hourFrom as number)

    return decimalHourToSexagesimal(delta)
  }

  const selectSlot = (slotDecimalHour: number) => () => {
    if (!hourFrom) {
      onChange({ hourFrom: slotDecimalHour, hourTo: (slotDecimalHour + SLOT_STEP) })
    } else {
      if (slotDecimalHour > hourFrom) {
        if (!hasExceptionDate(hourFrom, slotDecimalHour)) {
          onChange({ hourFrom, hourTo: (slotDecimalHour + SLOT_STEP) })
        }
      } else if (slotDecimalHour < hourFrom){
        if (!hasExceptionDate(slotDecimalHour, hourFrom)) {
          onChange({ hourFrom: slotDecimalHour, hourTo: hourTo })
        }
      }
    }
  }

  const hasExceptionDate = (fromH: number, toH: number) => {
    if (!takenTimeSlots || takenTimeSlots.length === 0) {
      return false;
    }

    const exceptions = TIME_TABLE.filter((time: number, index: number) => {
      if (time >= fromH && time <= toH) {
        return takenTimeSlots.includes(index)
      }
    })

    return exceptions.length > 0
  }

  const renderTimeSlot = (slotDecimalHour: number, slotIndex: number) => {
    const isInSlotRange = slotDecimalHour >= (hourFrom as number) && slotDecimalHour < (hourTo as number)
    const isException = takenTimeSlots.includes(slotIndex)

    const slotValue = `${decimalHourToSexagesimal(slotDecimalHour)} - ${decimalHourToSexagesimal(slotDecimalHour + 1/slotsPerHour)}`
    return (
      <StyledMenuItem
        disabled={isException}
        selected={isInSlotRange}
        key={slotDecimalHour}
        onClick={selectSlot(slotDecimalHour)}
        className={clsx({
          [classes.exception]: isException,
          [classes.selected]: isInSlotRange,
        })}
      >
        {slotValue}
      </StyledMenuItem>
    )
  }

  const renderTimeTable = () => TIME_TABLE.map(
    (slotDecimalHour: number, slotIndex) => renderTimeSlot(slotDecimalHour, slotIndex)
  )

  return (
    <Grid container spacing={2} direction='column'>
      <Grid item xs className={classes.fullWidth}>
        <FormHelperText error={error}>
          {helperText}
        </FormHelperText>
        <Paper className={classes.paper} elevation={2}>
          <MenuList>
            { renderTimeTable() }
          </MenuList>
        </Paper>
      </Grid>
      <Grid item>
        {
          isRange &&
          <Typography>
            {t('components.range', { duration:  getSlotDuration() })}
          </Typography>
        }
      </Grid>
      {
        isRange &&
        <Grid item container justify='center' xs>
          <Button
            variant='contained'
            color='primary'
            endIcon={<DeleteOutlined />}
            onClick={resetTime}
          >
            {t('button.change')}
          </Button>
        </Grid>
      }
    </Grid>
  )
}
