import React from 'react'
import { Link } from '@reach/router'
import { makeStyles } from '@material-ui/core/styles'
import {
  Typography,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
} from '@material-ui/core'

import { AddPerformer } from 'components/logos/gmd-add-performance'
import { GMDYellow } from 'components/logos/gmd-main-yellow'

const useStyles = makeStyles((theme) => ({
  card: {
    height: 312,
    width: 240,
    background: theme.palette.primary.main,
  },
  media: {
    height: 240,
    width: 240,
  },
  link: {
    textDecoration: 'none',
  },
  actionArea: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  name: {
    textOverflow: 'ellipsis',
    fontWeight: 600,
    fontSize: '1rem',
    lineHeight: '1.2rem',
  },
  cardContent: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
  }
}))

interface Props {
  name: string;
  link: string;
  imageUrl?: string;
  add?: boolean;
};

export const MediaCard = (props: Props) => {
  const {
    imageUrl,
    link,
    name,
    add,
  } = props;
  const classes = useStyles(props);

  return (
    <Link to={link} className={classes.link}>
      <Card className={classes.card} elevation={4}>
        <CardActionArea className={classes.actionArea}>
          { imageUrl &&
            <CardMedia
              className={classes.media}
              image={imageUrl}
              title={name}
            />
          }
          { !imageUrl && add &&
            <AddPerformer width={190} height={240} />
          }
          {
            !imageUrl && !add &&
            <GMDYellow width={200} height={240} />
          }
          <CardContent className={classes.cardContent}>
            <Typography variant='h6' className={classes.name}>
              { name }
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </Link>
  );
}
