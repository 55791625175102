import React, { ChangeEvent } from 'react'
import {
  Typography,
  makeStyles,
  Card,
  CardMedia,
  CardContent,
  CardActionArea,
  Theme,
  FormHelperText,
  Box,
  Button,
} from '@material-ui/core'
import { Close } from '@material-ui/icons'

import { useTranslation } from 'react-i18next'

import { AddPhoto } from 'components/logos/gmd-add-photo'

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    height: '100%',
  },
  cardContent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    height: '100%',
    padding: 0,
    cursor: 'pointer',
    '&:last-child': {
      paddingBottom: 0,
    }
  },
  fileInput : {
    display: 'none',
    width: '100%',
    height: '100%'
  },
  footerText: {
    padding: theme.spacing(1)
  }
}));

export interface Props {
  value?: string;
  name: string;
  onChange: (file?: File) => void;
  helperText?: string;
  error?: boolean;
}

export const LogoSelection = ({ value, name, onChange, error, helperText }: Props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const imageUrl = typeof value === 'string'
    ? value
    : URL.createObjectURL(value)

  return (
    <>
    <Card className={classes.card} elevation={3}>
      <CardActionArea>
        <label>
          <CardContent className={classes.cardContent}>
            {
              imageUrl
              ? (<CardMedia component='img' image={imageUrl} />)
              : (
                <>
                  <Box padding={2}><AddPhoto width={200} /></Box>
                  <Typography className={classes.footerText} variant='h6' align='center'>
                    {t('participate.add-logo')}
                  </Typography>
                </>
              )
              }
              <input
                type='file'
                className={classes.fileInput}
                name={name}
                accept='image/*'
                onChange={({ target : { files } }: ChangeEvent<HTMLInputElement>) => {
                  onChange((files as FileList)[0]);
                }}
              />
              { helperText &&
                <FormHelperText error={error}>
                  {helperText}
                </FormHelperText>
              }
            </CardContent>
          </label>
        </CardActionArea>
    </Card>
    {
      imageUrl &&
      <Box zIndex={1000}>
          <Button endIcon={<Close />} onClick={() => { onChange(undefined); }}>
            {t('button.change')}
          </Button>
      </Box>
    }
    </>
  )
}
