import React from 'react';
import { Link } from 'gatsby';
import { Typography, makeStyles } from '@material-ui/core';
import CLIENT_ROUTES from 'constants/endpoints';
import { useTranslation } from 'react-i18next';
import blue from '@material-ui/core/colors/blue';

const useStyles = makeStyles({
  link: {
    color: blue[600],
  },
});

export const ForgotPasswordLink = () => {
    const { t } = useTranslation();
    const classes = useStyles();
    return (
        <Link
            className={classes.link}
            to={CLIENT_ROUTES.RESET_PASSWORD}
          >
            <Typography align='right'>
              {t('reset-password.forgot-password-question')}
            </Typography>
          </Link>
    )
}