import React from 'react'
import { useTranslation } from 'react-i18next'
import { Layout } from 'components/layouts/main-layout'
import { Wizard } from './components/wizard'

interface Props {
  performerId: number,
  performanceId: number,
}

export const ParticipantFlow = ({ performerId, performanceId }: Props) => {
  const { t } = useTranslation()

  return (
    <Layout
      white
      pageName={t('page.participate')}
    >
        <Wizard performerId={performerId} performanceId={performanceId} />
    </Layout>

  )
}
