import React, { SVGProps } from 'react';

export const AddPhoto = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={166.595}
    height={165.896}
    viewBox="0 0 166.595 165.896"
    {...props}
  >
    <g data-name="Group 124">
      <g data-name="Group 47">
        <path
          data-name="Path 13"
          d="M25.934 13a1.312 1.312 0 00-1.312 1.312V23.5a1.312 1.312 0 01-1.312 1.31H4.937a1.312 1.312 0 01-1.312-1.31V13a1.312 1.312 0 011.312-1.312h2.625a1.312 1.312 0 001.312-.892l.709-2.152a1.312 1.312 0 011.247-.892h7.23a1.312 1.312 0 100-2.625h-7.3a3.937 3.937 0 00-3.727 2.69l-.42 1.312H4.937A3.937 3.937 0 001 13.064v10.5A3.937 3.937 0 004.937 27.5H23.31a3.937 3.937 0 003.937-3.937v-9.186A1.312 1.312 0 0025.934 13zm-11.811-1.314a5.249 5.249 0 105.249 5.249 5.249 5.249 0 00-5.249-5.249zm0 7.874a2.625 2.625 0 112.625-2.625 2.625 2.625 0 01-2.625 2.625zM28.559 5.125h-1.312V3.812a1.312 1.312 0 10-2.625 0v1.313H23.31a1.312 1.312 0 100 2.625h1.312v1.312a1.312 1.312 0 102.625 0V7.749h1.312a1.312 1.312 0 100-2.625z"
          transform="translate(15258.001 4763) translate(-15461.063 -4957) translate(339.785 191.5)"
        />
        <g data-name="Group 7">
          <g data-name="Group 4" fillRule="evenodd">
            <path
              data-name="Path 1"
              d="M8265.289 12819.527h-57.1c0 9.755 2.646 16.6 7.8 21.138 8.658 7.613 21.168 11.2 32.881 6.23 6.58-2.785 12.192-7.415 14.691-14.257a35.923 35.923 0 001.728-13.111z"
              transform="translate(15258.001 4763) translate(-15461.063 -4957) translate(-1551.938 179.337) translate(1755 14.75) translate(-8153.584 -12747.985)"
            />
            <path
              data-name="Path 2"
              d="M3378.184 6386.349c3.814-.751 8.4 5.112 11.617 8.324l40.351 40.187c1.686 1.687 3.217 3.448 4.886 5.116l20.035 19.971c1.286 1.284 3.866 3.2 4.34 5.643.645 3.317-2.062 5.176-3.434 6.529l-55.372 55.17c-3.4 3.4-6.649 6.56-10.05 9.952-1.672 1.668-3.2 3.43-4.885 5.116-1.177 1.178-3.5 3.81-5.73 4.273-3.063.638-5.458-2.119-6.653-3.318L3303 6473.049c-5.165-5.018-6.493-6.781-.931-12.187 1.747-1.7 3.278-3.269 5-4.994l65.272-65.274c1.294-1.286 3.305-3.745 5.843-4.245zm-.313-2.658c-3.98.674-8.072 5.794-11.428 9.154l-62.51 62.245c-6.427 6.427-12.412 10.429-3.948 18.874l15.561 15.5c6.994 6.993 13.847 13.748 20.842 20.744l31.255 31.12c1.774 1.776 3.415 3.266 5.076 5.187a8.985 8.985 0 007.526 2.805c3.593-.579 8.388-6.223 11.353-9.191l36.352-36.29c1.831-1.8 3.468-3.431 5.282-5.247l20.879-20.706c1.723-1.679 3.55-3.357 5.149-5.115 1.545-1.7 3.438-3.881 2.841-7.467-.631-3.794-6.08-8.328-9.145-11.392-3.517-3.517-6.859-6.916-10.42-10.373s-6.905-6.853-10.422-10.371c-3.626-3.625-6.857-6.823-10.414-10.379l-31.255-31.124c-1.7-1.7-3.546-3.359-5.122-5.14a8.464 8.464 0 00-7.452-2.831z"
              transform="translate(15258.001 4763) translate(-15461.063 -4957) translate(-1551.938 179.337) translate(1755 14.75) translate(-3295.806 -6383.59)"
            />
            <path
              data-name="Path 3"
              d="M4024.638 7031.116c1.844-1.184 3.047.842 5.359 3.154 1.6 1.6 3.018 2.994 4.623 4.59l54.844 54.908c10.759 10.771 11.433 8.779 5.69 14.521l-64.089 63.827c-4.577 4.576-4.886 6.174-7.577 3.421-.8-.816-1.429-1.428-2.238-2.237l-59.6-59.366c-1.449-1.452-8.44-8.046-9.026-9.161-.883-1.684.361-2.392 1.13-3.123.82-.779 1.435-1.433 2.238-2.237 1.6-1.6 3-3.011 4.6-4.605 1.983-1.969 7.6-7.365 9.021-9.139 2.184-2.712 41.121-41.037 45.708-45.623 1.618-1.612 7.532-7.781 9.317-8.93zm-.063-3c-2.461.664-8.091 6.954-10.106 8.969l-61.721 61.453c-6.392 6.364-2.344 8.008 5.923 16.275l47.474 47.278c.848.847 1.546 1.507 2.373 2.364l11.74 11.947c6.525 6.541 8.044 2.351 16.547-5.914l47.351-47.4c3.224-3.221 6.267-6.238 9.49-9.459 1.639-1.641 3.081-3.124 4.741-4.735 1.181-1.148 3.885-3.284 3-6.254-.74-2.478-7.09-8.157-8.94-10.006l-61.72-61.457c-1.227-1.229-3.235-3.855-6.152-3.066z"
              transform="translate(15258.001 4763) translate(-15461.063 -4957) translate(-1551.938 179.337) translate(1755 14.75) translate(-3942.336 -7020.803)"
            />
            <path
              data-name="Path 4"
              d="M8214.6 12126.984c-.445-.416-.261.033-.409-.719a2.48 2.48 0 01.074-.69l2.256-.134c.891.13.418.119.656.228l1.175.386c.237-1.091.287-1.228-.159-2.037-.924-.115-.718.241-1.937.337l-7.431-.3c-.5.8-.44.891-.18 1.905a25.059 25.059 0 014.421-.411l.065 1.461-4.975.02v3.71h57.25v-3.656l-5.441-.081.01-1.456c3.567.011 2.439.382 3.9.418.756-2.766-.127-1.594-4.458-1.6a17.888 17.888 0 01-3.82-.145c-1.512-.382-1.83-.343-1.434 1.751 1.209-.011.687-.419 4.4-.419l.087 1.461h-21.081l.029-1.464c2.536.007 3.42.472 4.046.279l.1-1.576c-1.295-.384-5.666.556-8.9-.169-1.366-.3-1.274.524-.941 1.792.469.077 2.982-.373 4.5-.29l.063 1.384z"
              transform="translate(15258.001 4763) translate(-15461.063 -4957) translate(-1551.938 179.337) translate(1755 14.75) translate(-8153.553 -12060.185)"
            />
            <path
              data-name="Path 5"
              d="M8753.974 15550.9l.017 21.23a2.184 2.184 0 00.708 4.268 1.8 1.8 0 002.073-1.791c.153-1.531-.3-2.156-1.3-2.484v-21.289c1.144.771 14.888 18.07 16.9 19.783a3.762 3.762 0 002.234 4.652c3.254 1.027 3.829-4.4-.2-5.34-1.639-.381-2.185-1.545-3.957-3.676l-12.314-14.264c-2.165-2.377-.95-2.342-5.3-2.316l-5.261 6.006a14.069 14.069 0 00-1.336 1.566l-8.15 9.225c-.486.6-.914.895-1.4 1.48-.251.309-.321.484-.561.771-1.3 1.545-1.5-.068-3.023 1.443-3.364 3.344 1.108 6.4 3.1 3.086 2.079-3.473-3.277.977 6.771-10.07z"
              transform="translate(15258.001 4763) translate(-15461.063 -4957) translate(-1551.938 179.337) translate(1755 14.75) translate(-8671.468 -15447.775)"
            />
            <path
              data-name="Path 6"
              d="M9591.963 9748.307c-3.716 2.342-20.43 14.426-22.124 15.215a3.127 3.127 0 001.119 1.709l17.646-12.363c.57-.4 4.017-2.922 4.334-2.99.159-.033 3.631 1.479 4.922-1.2 1.163-2.412-2.493-6.471-5.071-3.465-.956 1.121-.561 1.682-.826 3.094z"
              transform="translate(15258.001 4763) translate(-15461.063 -4957) translate(-1551.938 179.337) translate(1755 14.75) translate(-9500.1 -9706.792)"
            />
            <path
              data-name="Path 7"
              d="M9488.328 9724.183a4.671 4.671 0 001.846-1.24c-1.266-.846-2.633-1.833-4.008-2.789a29.86 29.86 0 00-4.191-2.677c.342-5.367-4.977-5.205-5.9-1.329a2.479 2.479 0 001.418 2.936c1.775 1.081 2.24-.017 3.287.011.539.018 7.027 4.622 7.547 5.089z"
              transform="translate(15258.001 4763) translate(-15461.063 -4957) translate(-1551.938 179.337) translate(1755 14.75) translate(-9407.285 -9676.316)"
            />
            <path
              data-name="Path 8"
              d="M10886.18 10782.981l9.4 6.395a23.333 23.333 0 003.4 2.221l1.04-1.4-12.091-8.511a4.637 4.637 0 00-1.749 1.295z"
              transform="translate(15258.001 4763) translate(-15461.063 -4957) translate(-1551.938 179.337) translate(1755 14.75) translate(-10801.807 -10732.797)"
            />
            <path
              data-name="Path 9"
              d="M8750.241 12255.544l-.153-1.312h2.729c-.234-.111.239-.1-.654-.23l-2.258.134a2.384 2.384 0 00-.073.688c.149.751-.032.304.409.72z"
              transform="translate(15258.001 4763) translate(-15461.063 -4957) translate(-1551.938 179.337) translate(1755 14.75) translate(-8689.194 -12188.742)"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
