import React, { useState } from 'react'
import { navigate } from 'gatsby'
import { gql } from 'apollo-boost'
import { useQuery } from '@apollo/react-hooks'
import { Grid, MenuItem, Button, Typography, ListItemIcon, makeStyles } from '@material-ui/core'
import { Power, PowerOff } from '@material-ui/icons'
import { object, string, boolean } from 'yup'
import { useTranslation } from 'react-i18next'
import { FormRenderProps } from 'react-final-form'
import { path } from 'ramda'

import { Form } from 'components/final-form/form'
import { Field } from 'components/final-form/field'
import { SelectField } from 'components/select-field'
import { SwitchField } from 'components/switch-field'
import { LocationSelector } from './location-selector'
import { ProgressButton } from 'components/progress-button'
import { useQueryVisibleCities } from 'components/admin/common/city-selector'
import { isValue } from 'utilities'
import { Alert } from 'components/alert'
import { FindPerformanceResponse, Performance, City, Zone, FindZones } from 'types'
import ENDPOINTS from 'constants/endpoints'

import { StepProps } from './wizard'

const useFormStyles = makeStyles({
  fullWidth: {
    width: '100%'
  }
});

type Id = string | null;

export interface FormValues {
  cityId?: Id;
  zoneId?: Id;
  locationId?: Id;
  isAcoustic?: boolean;
}

type Props = StepProps

export const PerformanceLocation = ({ previousStep, onSubmit, data : wizardData }: Props) => {
  const { t } = useTranslation()
  const classes = useFormStyles()
  const [ performance, setPerformance ] = useState<Performance>()
  const [ zones, setZones ] = useState<Array<Zone>>()
  const [ initial, setInitial ] = useState<FormValues>({
    locationId: wizardData.locationId,
    zoneId: wizardData.zoneId,
    cityId: wizardData.cityId,
    isAcoustic: wizardData.isAcoustic || false,
  })
  const isCreate = window && window.location.pathname.includes('create')

  const schema = object().shape({
    locationId: string().nullable().required(),
    zoneId: string().nullable(),
    cityId: string().nullable(),
    isAcoustic: boolean().nullable(),
  })

  if (wizardData.performanceId && !wizardData.locationId) {
    const { data: performanceData } = useQuery<FindPerformanceResponse>(FIND_PERFORMANCE, {
      variables: {
        id: wizardData.performanceId,
      },
    })
    const isSamePerformer = () => isValue(performanceData)
      ? path(['performance', 'performer', 'id'], performanceData) === wizardData.performerId
      : true

    if (!isSamePerformer()) {
      navigate(ENDPOINTS.PARTICIPATE.HOME)
    }

    if (!performance && performanceData) {
      setPerformance(performanceData.performance)
      const cityId = path(['performance', 'location', 'city', 'id'], performanceData)
      const locationId = path(['performance', 'location', 'id'], performanceData)
      const isAcoustic = path(['performance', 'location', 'zone', 'is_acoustic'], performanceData)
      setInitial((current: FormValues) => ({ ...current, cityId, locationId, isAcoustic }))
    }
  }

  const RegistrationForm = ({ handleSubmit, submitting, hasValidationErrors, form, values }: FormRenderProps<FormValues>) => {
    const { data: cityData } = useQueryVisibleCities();
    const cityId = values.cityId
    const zoneId = values.zoneId
    const isAcoustic = values.isAcoustic
    const { data: zonesData } = useQuery<FindZones>(FIND_ZONES, {
      variables: {
        city: cityId,
      },
      skip: cityId === undefined
    });
    if (zonesData && !zones) {
      setZones(() => zonesData.zones)
    }

    const handleCityChange = () => {
      setZones(() => undefined)
      if (zonesData) {
        // @ts-ignore
        zonesData.zones = undefined;
      }
    }

    const getZones = () => {
      if (isValue(isAcoustic)) {
        return (!!zones)
          ? zones.filter(zone => zone.is_acoustic === isAcoustic)
          : []
      }
      return zones || []
    }

    const handleToggleAcoustic = () => {
      form.change('zoneId', null)
    }

    const center = cityData?.cities.find(({ id }) => id === cityId)?.coordinates

    return (
      <form noValidate onSubmit={handleSubmit}>
        <Grid container spacing={4} alignItems='center' direction='column'>
          <Grid item xs={12} className={classes.fullWidth}>
            <Field
              name='cityId'
              label={t('participate.city')}
              component={SelectField}
              onChange={handleCityChange}
            >
              {
                cityData && cityData.cities.map(({ id, name }: City) =>
                  <MenuItem key={id} value={id}>{name}</MenuItem>)
              }
            </Field>
          </Grid>
          {
            !!cityId &&
            <Grid item xs={12} className={classes.fullWidth}>
              <Field
                name='isAcoustic'
                label={t('participate.electricity')}
                type='imusthaveatype'
                component={SwitchField}
                color='primary'
                onChange={handleToggleAcoustic}
              />
            </Grid>
          }
          {
            !!cityId &&
            <Grid item xs>
              <Alert type='warning'>
                { isAcoustic ? t('participate.acoustic') : t('participate.non-acoustic') }
              </Alert>
            </Grid>
          }
          {
            !!cityId &&
            <Grid item xs={12} className={classes.fullWidth}>
              <Field
                name='zoneId'
                label={t('participate.zone')}
                component={SelectField}
              >
                <MenuItem key='all' value={''}>
                  <Typography variant='inherit'>
                    {t('participate.all')}
                  </Typography>
                </MenuItem>
                {
                  getZones().map(({ id, name, is_acoustic }: Zone) =>
                    <MenuItem key={id} value={id}>
                      <ListItemIcon>
                        { is_acoustic ? <PowerOff /> : <Power /> }
                      </ListItemIcon>
                      <Typography variant='inherit'>
                        {name}
                      </Typography>
                    </MenuItem>
                  )
                }
              </Field>
            </Grid>
          }
          {
            center &&
              <Grid item className={classes.fullWidth}>
                <Field
                  name='locationId'
                  cityId={cityId}
                  zoneId={zoneId}
                  isAcoustic={isAcoustic}
                  component={LocationSelector}
                  center={center}
                />
              </Grid>
          }
          <Grid item className={classes.fullWidth}>
            <Grid container justify='space-between' alignItems='center'>
              <Grid item>
                {
                  isCreate &&
                  <Button
                    variant='outlined'
                    onClick={previousStep}
                  >
                    {t('button.previous')}
                  </Button>
                }
              </Grid>
              <Grid item>
              <ProgressButton
                variant='contained'
                color='primary'
                type='submit'
                disabled={submitting || hasValidationErrors}
                progress={submitting}
              >
                {t('button.next')}
              </ProgressButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
    )
  }

  return (
    <Form
      onSubmit={onSubmit}
      validationSchema={schema}
      initialValues={initial}
      render={RegistrationForm}
    />
  )
}

const FIND_ZONES = gql`
  query zones($city: ID!, $is_acoustic: Boolean) {
    zones (where:  { city: $city, is_acoustic: $is_acoustic} ) { id, name, is_acoustic }
  }
`;

const FIND_PERFORMANCE = gql`
  query performance($id: ID!) {
    performance (id: $id) { performer { id } location { id city { id } zone { is_acoustic } } }
  }
`;
