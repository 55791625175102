import React, { SVGProps } from 'react';

export const AddPerformer = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={240.948}
    height={240.081}
    viewBox="0 0 240.948 240.081"
    {...props}
  >
    <g data-name="Group 123">
      <g data-name="Group 55">
        <g data-name="Group 53">
          <g data-name="Group 4" fillRule="evenodd">
            <path
              data-name="Path 1"
              d="M8290.865 12819.527h-82.676c0 14.125 3.83 24.039 11.3 30.605 12.535 11.025 30.648 16.219 47.607 9.025 9.525-4.037 17.654-10.738 21.275-20.648 2.141-5.87 2.629-12.269 2.494-18.982z"
              transform="translate(15741.796 4799.976) translate(-16195.796 -5275.725) translate(454 475.749) translate(-8129.125 -12715.939)"
            />
            <path
              data-name="Path 2"
              d="M3415.083 6387.584c5.524-1.088 12.17 7.4 16.822 12.053l58.425 58.188c2.442 2.442 4.658 4.991 7.075 7.407l29.009 28.917c1.862 1.858 5.6 4.634 6.284 8.17.934 4.8-2.984 7.494-4.973 9.452l-80.174 79.885c-4.917 4.922-9.628 9.5-14.552 14.408-2.421 2.417-4.631 4.968-7.074 7.41-1.7 1.706-5.074 5.515-8.3 6.187-4.435.923-7.9-3.067-9.633-4.8l-101.768-101.736c-7.478-7.266-9.4-9.819-1.347-17.646 2.53-2.459 4.746-4.734 7.247-7.232l94.511-94.513c1.865-1.865 4.774-5.424 8.448-6.15zm-.454-3.85c-5.762.978-11.687 8.392-16.547 13.256l-90.511 90.127c-9.307 9.307-17.972 15.1-5.717 27.326l22.532 22.438c10.127 10.126 20.05 19.906 30.177 30.035l45.256 45.061c2.569 2.571 4.945 4.729 7.35 7.51 1.86 2.148 6.367 4.788 10.9 4.061 5.2-.837 12.146-9.01 16.438-13.308l52.635-52.546c2.651-2.6 5.021-4.966 7.649-7.6l30.231-29.981c2.494-2.432 5.14-4.861 7.456-7.406 2.237-2.461 4.978-5.619 4.114-10.812-.914-5.493-8.8-12.059-13.242-16.5-5.092-5.093-9.932-10.012-15.087-15.019s-10-9.922-15.091-15.016c-5.25-5.251-9.929-9.88-15.078-15.028l-45.255-45.065c-2.464-2.463-5.134-4.864-7.417-7.442-2.206-2.493-6.333-4.854-10.791-4.1z"
              transform="translate(15741.796 4799.976) translate(-16195.796 -5275.725) translate(454 475.749) translate(-3295.806 -6383.59)"
            />
            <path
              data-name="Path 3"
              d="M4058.249 7032.526c2.67-1.713 4.412 1.22 7.76 4.569 2.317 2.318 4.37 4.335 6.694 6.645l79.411 79.506c15.578 15.593 16.554 12.712 8.238 21.024l-92.8 92.417c-6.627 6.625-7.074 8.939-10.97 4.952-1.16-1.183-2.07-2.066-3.242-3.239l-86.294-85.959c-2.1-2.1-12.221-11.648-13.068-13.264-1.278-2.437.522-3.463 1.636-4.521 1.188-1.129 2.078-2.076 3.242-3.24 2.314-2.315 4.344-4.358 6.667-6.668 2.872-2.851 11-10.666 13.063-13.232 3.162-3.927 59.541-59.42 66.182-66.061 2.332-2.332 10.894-11.265 13.481-12.929zm-.093-4.35c-3.563.961-11.715 10.068-14.633 12.987l-89.367 88.981c-9.256 9.213-3.394 11.592 8.575 23.563l68.74 68.456c1.229 1.227 2.238 2.181 3.436 3.424l17 17.3c9.448 9.47 11.647 3.4 23.959-8.563l68.561-68.633c4.668-4.663 9.075-9.031 13.741-13.7 2.374-2.374 4.46-4.522 6.864-6.854 1.711-1.664 5.625-4.757 4.342-9.057-1.07-3.585-10.265-11.81-12.944-14.486l-89.367-88.987c-1.763-1.771-4.682-5.573-8.907-4.431z"
              transform="translate(15741.796 4799.976) translate(-16195.796 -5275.725) translate(454 475.749) translate(-3939.08 -7017.593)"
            />
            <path
              data-name="Path 4"
              d="M8217.486 12128.325c-.646-.6-.379.046-.592-1.04a3.526 3.526 0 01.105-1l3.268-.193c1.29.187.605.173.949.329l1.7.559c.344-1.58.416-1.776-.231-2.949-1.335-.169-1.038.351-2.8.488l-10.758-.441c-.72 1.151-.641 1.29-.262 2.755 1.444.021 1.367-.594 6.4-.594l.094 2.118-7.2.027v5.371h82.893v-5.294l-7.876-.119.014-2.105c5.165.014 3.531.552 5.649.605 1.094-4.006-.185-2.31-6.456-2.321a26.04 26.04 0 01-5.531-.21c-2.19-.554-2.65-.5-2.076 2.536 1.751-.017.994-.608 6.374-.608l.124 2.118h-30.523l.044-2.12c3.67.007 4.95.68 5.857.4l.138-2.283c-1.874-.557-8.2.806-12.892-.245-1.979-.437-1.845.759-1.363 2.6.678.11 4.319-.539 6.523-.42l.091 2z"
              transform="translate(15741.796 4799.976) translate(-16195.796 -5275.725) translate(454 475.749) translate(-8129.093 -12031.604)"
            />
            <path
              data-name="Path 5"
              d="M8763.862 15551.446l.026 30.744c-3.174 1.07-2.989 6.207 1.025 6.18 1.858-.02 2.821-.809 3-2.6.223-2.219-.443-3.121-1.881-3.6v-30.824c1.654 1.117 21.556 26.168 24.468 28.645a5.447 5.447 0 003.236 6.736c4.712 1.486 5.544-6.367-.285-7.73-2.372-.553-3.159-2.24-5.729-5.32l-17.83-20.656c-3.134-3.445-1.374-3.393-7.67-3.352l-7.619 8.7a20.256 20.256 0 00-1.936 2.266l-11.8 13.355c-.705.875-1.325 1.295-2.023 2.146-.364.443-.468.7-.812 1.111-1.879 2.238-2.18-.094-4.38 2.094-4.87 4.844 1.606 9.27 4.485 4.469 3.008-5.029-4.747 1.412 9.8-14.584z"
              transform="translate(15741.796 4799.976) translate(-16195.796 -5275.725) translate(454 475.749) translate(-8644.399 -15402.135)"
            />
            <path
              data-name="Path 6"
              d="M9601.873 9750.169c-5.382 3.389-29.579 20.885-32.033 22.028a4.526 4.526 0 001.62 2.473l25.548-17.9c.827-.582 5.817-4.23 6.277-4.331.231-.049 5.256 2.144 7.124-1.729 1.688-3.494-3.609-9.369-7.342-5.016-1.382 1.615-.81 2.428-1.194 4.475z"
              transform="translate(15741.796 4799.976) translate(-16195.796 -5275.725) translate(454 475.749) translate(-9468.861 -9690.059)"
            />
            <path
              data-name="Path 7"
              d="M9493.857 9729.043a6.8 6.8 0 002.672-1.795c-1.833-1.228-3.812-2.655-5.8-4.041-1.493-1.035-4.539-3.286-6.069-3.873.5-7.773-7.2-7.539-8.542-1.926-.545 2.276.687 3.417 2.054 4.251 2.568 1.564 3.244-.025 4.759.017.78.021 10.174 6.691 10.926 7.367z"
              transform="translate(15741.796 4799.976) translate(-16195.796 -5275.725) translate(454 475.749) translate(-9376.513 -9659.735)"
            />
            <path
              data-name="Path 8"
              d="M10886.179 10783.561l13.611 9.258a33.858 33.858 0 004.927 3.216l1.508-2.024-17.508-12.321a6.67 6.67 0 00-2.538 1.871z"
              transform="translate(15741.796 4799.976) translate(-16195.796 -5275.725) translate(454 475.749) translate(-10764.012 -10710.896)"
            />
            <path
              data-name="Path 9"
              d="M8750.432 12256.232l-.225-1.9h3.953c-.34-.16.344-.145-.947-.334l-3.271.193a3.557 3.557 0 00-.105 1c.216 1.088-.05.438.595 1.041z"
              transform="translate(15741.796 4799.976) translate(-16195.796 -5275.725) translate(454 475.749) translate(-8662.037 -12159.508)"
            />
          </g>
        </g>
        <g data-name="Group 54">
          <path
            data-name="Path 14"
            d="M22.344 42.759a20.344 20.344 0 1120.344-20.344 20.366 20.366 0 01-20.344 20.344zm0-37.433a17.089 17.089 0 1017.089 17.089A17.108 17.108 0 0022.344 5.326z"
            transform="translate(15741.796 4799.976) translate(-16195.796 -5275.725) translate(653.837 476.319) translate(-2 -2.071)"
          />
          <path
            data-name="Path 15"
            d="M15.128 30.356a1.628 1.628 0 01-1.628-1.627V9.2a1.628 1.628 0 013.255 0v19.53a1.628 1.628 0 01-1.627 1.626z"
            transform="translate(15741.796 4799.976) translate(-16195.796 -5275.725) translate(653.837 476.319) translate(5.217 1.38)"
          />
          <path
            data-name="Path 16"
            d="M28.658 16.826H9.128a1.628 1.628 0 110-3.255h19.53a1.628 1.628 0 110 3.255z"
            transform="translate(15741.796 4799.976) translate(-16195.796 -5275.725) translate(653.837 476.319) translate(1.451 5.146)"
          />
        </g>
      </g>
    </g>
  </svg>
);
