import React, { ElementType } from 'react'
import { Paper, Box, Grid, Typography, makeStyles, Theme, fade, IconProps } from '@material-ui/core'
import { Error, Info, Warning } from '@material-ui/icons'

const getBgColor = (color: string) => fade(color, 0.45)

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    backgroundColor: ({ type = 'info' }: Props) =>
      getBgColor(theme.palette[type].main),
  },
  icon: {
    fill: ({ type = 'info' }: Props) => theme.palette[type].dark,
  },
}))

type Icon = ElementType<IconProps>;

interface Props {
  type?: 'info' | 'warning' | 'error' | 'success';
  children: any;
}

export const Alert = (props: Props) => {
  const { type = 'info', children } = props
  const classes = useStyles(props)
  // @ts-ignore
  const Icon: Icon = (function() {
    switch(type) {
      case 'info':
      case 'success':
        return Info
      case 'warning':
        return Warning
      case 'error':
        return Error
    }
  })()

  return (
    <Paper className={classes.paper} elevation={0}>
      <Grid container alignItems='center' spacing={2} wrap='nowrap'>
        <Grid item>
          <Box pl={2}>
            <Icon className={classes.icon} />
          </Box>
        </Grid>
        <Grid item xs>
          <Typography>
            { children }
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  )
}
